import * as ko from "knockout";
import {Context} from "@profiscience/knockout-contrib-router";
import {EvaluationDto, IdeaDto, UserDto} from "../../api/generated";
import {evaluationApi, ideaApi, userApi} from "../../api/api-wrapper";
import {observable} from "knockout-decorators";
import {bonus} from "./evaluationUtils";
import "../../components/elements/idea/evaluation-idea"
import "../../components/elements/attachments/images-carousel"


class ViewModelContext extends Context {
    evaluation: EvaluationDto;
    adminContacts: UserDto[];
}

class ViewModel {

    /**
     * The evaluation.
     */
    @observable({deep: true, expose: true})
    public evaluation: EvaluationDto;

    /**
     * The admin contact user.
     */
    public adminContacts: UserDto[];

    /**
     * The admin description or the expert users description.
     */
    public evaluationDescription: KnockoutComputed<string>;

    /**
     * The bonus according to the score of all criterias
     */
    public bonus: KnockoutComputed<number>;

    /**
     * Constructor.
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        this.evaluation = ctx.evaluation;
        this.adminContacts = ctx.adminContacts;
        this.bonus = bonus(this.evaluation);
        this.evaluationDescription = ko.pureComputed(() => {
            if (this.evaluation.adminDescription && this.evaluation.adminDescription.trim().length > 0) {
                return this.evaluation.adminDescription;
            }
            return this.evaluation.description;
        });

        console.debug("View evaluation: ", this.evaluation);
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./evaluation.html'),
    componentName: "evaluation",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Gutachten ${ctx.params && ctx.params.id || ''}`;
        return Promise.all([
            evaluationApi.getEvaluation(ctx.params.id).then(evaluation => {
                ctx.evaluation = evaluation;
                return ideaApi.getIdea(evaluation.idea.id).then((idea) => ctx.evaluation.idea = idea);
            }),
            userApi.getAdminUsers().then(users =>
                ctx.adminContacts = users
            )
        ]);
    }
};
