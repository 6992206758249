import {Context, Router} from "@profiscience/knockout-contrib-router";
import {Evaluation, EvaluationDto, IdeaDto} from "../../api/generated";
import {evaluationApi, ideaApi, userApi} from "../../api/api-wrapper";
import {autobind, observable, unwrap} from "knockout-decorators";
import {ideaAttachmentsRegex} from "../ideas/ideaUtils";
import {App} from "../../app";
import globalState from "../../global-state"
import {postbox} from "../../components/util/postbox";
import * as ko from "knockout";
import {UserSearchForm} from "../../forms/user-search-form";
import {bonus, evaluationDeadline} from "./evaluationUtils";
import {FileUploadViewModel} from "../../utils/FileUploadViewModel";
import "../../components/elements/idea/evaluation-idea"
import ImplementEnum = Evaluation.ImplementEnum;
import PostponeReasonEnum = Evaluation.PostponeReasonEnum;


class ViewModelContext extends Context {
    evaluation: EvaluationDto;
    idea: IdeaDto;
}

/**
 * Form to assign the evaluation to a new user.
 */
class HandoverForm extends UserSearchForm {

    /**
     * The comment.
     */
    public comment: KnockoutObservable<string>;

    /**
     * Constructor.
     */
    constructor() {
        super();
        this.comment = ko.observable("");
    }
}

class PostponeForm {
    public postponeUntil: KnockoutObservable<string> = ko.observable('').extend({required: true});
    public postponeReason: KnockoutObservable<PostponeReasonEnum> = ko.observable(null).extend({required: true});
}

class ViewModel extends FileUploadViewModel {

    /**
     * The evaluation.
     */
    @observable({deep: true, expose: true})
    public evaluation: EvaluationDto;

    /**
     * The idea.
     * The evaluation contains a IdeaSlim object only. To display the description, advantage and audience teh idea has
     * to be loaded separately.
     */
    public idea: IdeaDto;

    /**
     * The form to handover an evaluation to another user.
     */
    public handoverForm: HandoverForm;

    /**
     * Is the handover comment visible?
     */
    public handoverCommentVisible: boolean;

    /**
     * The form to postpone an evaluation.
     */
    public postponeForm: PostponeForm;

    /**
     * Validate as required only if the evaluation implement property equals to Implement.
     */
    public validateImplement: KnockoutComputed<boolean>;

    /**
     * The bonus according to the score of all criteria
     */
    public bonus: KnockoutComputed<number>;

    /**
     * Constructor.
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();
        this.evaluation = Object.assign({}, this.createEvaluation(), ctx.evaluation);
        this.idea = ctx.idea;
        this.handoverForm = new HandoverForm();
        this.postponeForm = new PostponeForm();
        this.validateImplement = ko.computed(() => this.evaluation.implement === ImplementEnum.Implement);
        this.bonus = bonus(this.evaluation);
        this.handoverCommentVisible = this.evaluation.handoverComment && this.evaluation.handoverComment.trim().length > 0;

        unwrap(this.evaluation, "implement").extend({required: true});
        unwrap(this.evaluation, "implementationDate").extend({
            required: {
                onlyIf: this.validateImplement
            }

        });
        unwrap(this.evaluation, "description").extend({required: true, maxlength: 65535});

        console.debug("Edit evaluation: ", this.evaluation);
    }

    /**
     * Postpone an evaluation.
     */
    @autobind
    public postpone() {
        const errors = ko.validation.group(this.postponeForm);
        if (errors().length > 0) {
            console.error("errors", errors);
            errors.showAllMessages();
            postbox.addError('validation.failed');
        } else {
            this.evaluation.postponeReason = this.postponeForm.postponeReason();
            this.evaluation.postponeAccepted = false;
            this.evaluation.postponeUntil = this.postponeForm.postponeUntil();
            this.evaluation.postponeTimestamp = new Date();
            this.save();
        }
    }

    @autobind
    public handover() {
        if (this.handoverForm.user() == null) {
            postbox.addError('validation.failed');
        } else {
            // Get the UserDto for the ActiveDirectoryUser
            return userApi.getUser(this.handoverForm.user().username, "username")
                .then(userDto => {
                    let evaluation: EvaluationDto = {
                        expertApproved: false,
                        submitted: false,
                        postponeAccepted: false,
                        expertUser: userDto,
                        handoverUser: globalState.user(),
                        handoverComment: this.handoverForm.comment()
                    };
                    // Post the new evaluation
                    return ideaApi
                        .postIdeaEvaluation(this.evaluation.idea.id, evaluation, globalState.sendMail())
                        .then(evaluation => {
                            // archive this evaluation
                            this.evaluation.archived = true;
                            return evaluationApi.putEvaluation(this.evaluation.id, this.evaluation)
                        })
                        .then(evaluation => {
                            postbox.addSuccess('evaluation.success.handover');
                            return Router.update(`/idee/${this.evaluation.idea.id}`, {
                                push: false,
                                force: true
                            });
                        })
                        .catch(reason => {
                            console.error(reason);
                            postbox.addError('evaluation.error.handover');
                        });
                })
                .catch(reason => {
                    console.error(reason);
                    postbox.addError('evaluation.error.handoverUser');
                })
                .finally(() => {
                    this.handoverForm.resetUser();
                    globalState.loading(false);
                });
        }
    }

    /**
     * Create an evaluation with all the properties which can be edited in this view.
     */
    private createEvaluation() {
        return {
            description: null,
            implement: null,
            implementationDate: null,
            implementationDescription: null,
            score: null,
            bonus: null,
            submitted: false,
            revise: false,
            evaluated: false,
            postponeUntil: null,
            postponeReason: null,
            active: false,
            criteriaList: [],
            submittedTimestamp: null,
            postponeTimestamp: null,
            implementationApproved: false
        }
    }

    /**
     * Get the options for the evaluation implement property
     */
    public implementOptions() {
        return App.enumOptions(ImplementEnum, "evaluation.implement.");
    }

    /**
     * Get the options for the postpone reason.
     */
    public postponeReasonOptions() {
        return App.enumOptions(PostponeReasonEnum, "evaluation.postponeReason.");
    }

    /**
     * Get the evaluation deadline.
     */
    @autobind
    public evaluationDeadline(): KnockoutComputed<Date> {
        return evaluationDeadline(this.evaluation);
    }

    /**
     * Save the evaluation.
     */
    @autobind
    private saveEvaluation(i18nSuffix: string, isSubmit: boolean) {
        console.debug("saving evaluation", this.evaluation);
        globalState.loading(true);
        return this.filesPromise(ideaAttachmentsRegex)
            .then(uploads =>
                this.evaluation.uploads = uploads)
            .then(value =>
                evaluationApi
                    .putEvaluation(this.evaluation.id, this.evaluation, globalState.sendMail())
                    .then(value => {
                        postbox.addSuccess(`evaluation.success.${i18nSuffix}`);
                        return Router.update(
                            isSubmit ?
                                `/profile/${globalState.user().id}` : `/gutachten/${this.evaluation.id}/erstellen`,
                            {
                                push: true,
                                force: false
                            });
                    }))
            .catch(err => {
                console.error('failed to save evaluation', err);
                postbox.addError("evaluation.error.save");
            })
            .finally(() => globalState.loading(false));
    }

    /**
     * Save the evaluation.
     */
    @autobind
    public save() {
        return this.saveEvaluation("save", false);
    }

    /**
     * Submit the evaluation.
     * Sets the evaluated flag to true and saves the evaluation.
     */
    @autobind
    submit() {
        const errors = ko.validation.group(this.evaluation);
        if (errors().length > 0) {
            console.debug("errors", errors());
            errors.showAllMessages();
            postbox.addError('validation.failed');
        } else {
            this.evaluation.submittedTimestamp = new Date();
            this.evaluation.submitted = true;
            this.saveEvaluation("submit", true);
        }
    }

    /**
     * Cancel the evaluation
     */
    @autobind
    cancel() {
        return Router.update(`/profile/${globalState.user().id}`, {
            push: true,
            force: false
        });
    }

}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./evaluate.html'),
    componentName: "evaluate",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Gutachten Erstellen ${ctx.params && ctx.params.id || ''}`;
        return evaluationApi.getEvaluation(ctx.params.id).then((evaluation) => {
            ctx.evaluation = evaluation;
            return ideaApi.getIdea(evaluation.idea.id).then((idea) =>
                ctx.idea = idea
            );
        }).catch(err => console.error(err));
    }
};
